/* eslint eslint-comments/disable-enable-pair: error */
/* eslint-disable no-undef, no-unused-vars */

import './scss/tailwind.scss';
import './scss/index.scss';
import './js/index';

/* eslint-enable no-undef, no-unused-vars */

import Alpine from 'alpinejs';

window.Alpine = Alpine;

Alpine.start();
