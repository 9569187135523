/**
 * Template Tag JS
 *
 * JS for functions in template-tags.php.
 */

import './mobile-menu';
import './modal';
import './navigation-primary';
import './off-canvas';
import './table';
import './video';
