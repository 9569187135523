const faders = document.querySelectorAll('.apei-fade-in');

const appearOptions = {
	threshold: 0,
	rootMargin: '0px 0px -10px 0px',
};

const appearOnScroll = new IntersectionObserver(function (entries) {
	entries.forEach((entry) => {
		if (!entry.isIntersecting) {
		} else {
			entry.target.classList.add('appear');
			appearOnScroll.unobserve(entry.target);
		}
	});
}, appearOptions);

faders.forEach((fader) => {
	appearOnScroll.observe(fader);
});
