menuLi = document.getElementById("site-mobile-menu").getElementsByTagName('li');

for (var i = 0; i < menuLi.length; i++) {
    menuLi[i].addEventListener('click', hideMenu, false);
}

function hideMenu() {
    menuButton = document.getElementById('lefty-menu-button');
    menuButton.classList.remove('is-visible');
    expanded = menuButton.getAttribute('aria-expanded');
    if (expanded == "true") {
        menuButton.setAttribute("aria-expanded", "false");
    }
    menuScreen = document.getElementById('lefty-menu-screen');
    menuScreen.classList.remove('is-visible');
    menuNav = document.getElementById('lefty-menu-container');
    menuNav.classList.remove('is-visible');
}
