/**
 * File video.js
 *
 * Deal with video playback.
 */

(function () {
	const videoButtons = document.querySelectorAll('.video-toggle');

	// Toggle playback on background videos.
	videoButtons.forEach((videoButton) => {
		videoButton.addEventListener('click', toggleVideoPlayback);
	});

	/**
	 * Toggle video playback when the button is pressed.
	 *
	 * @author Jo Murgel, Corey Collins
	 * @since January 31, 2020
	 * @param {Object} event The triggered event.
	 */
	function toggleVideoPlayback(event) {
		const targetParent = event.target.parentNode,
			targetElement = targetParent.querySelector('.video-background');

		targetParent.classList.toggle('video-toggled');

		if (targetParent.classList.contains('video-toggled')) {
			targetElement.pause();
		} else {
			targetElement.play();
		}
	}
})();
